(function () {
    'use strict';

    var queryDict = {};
    var arr = location.search.substr(1).split("&");
    for (var i = 0; i < arr.length; i++) {
        var item = arr[i];
        queryDict[item.split("=")[0]] = item.split("=")[1];
    }

    var YoutubeAPI = Plugin.YoutubeAPI = (function () {
        function YoutubeAPI() {
            var scope = this;
            this.init();
        }

        YoutubeAPI.prototype = {
            init: function () {
                var scope = this;
                var videos = [];
                var feed = 'https://content.googleapis.com/youtube/v3/playlistItems?maxResults=50&part=snippet&playlistId=FLUpenekp7EGpIjnhAeQeijw&key=AIzaSyDbt738Izct2OMus3YL5hDlfm58YjgH6WI';
                $.ajax({
                    dataType: 'json',
                    url: feed,
                    success: function (data) {
                        videos = data;
                        var fistVideo = "NlFEi1fWtug";
                        if (queryDict.v) {
                            fistVideo = queryDict.v;
                            setTimeout(function () {
                                var waitForTarget = setInterval(function () {
                                    if ($(".video-wrapper").length > 0) {
                                        clearInterval(waitForTarget);

                                        $("html, body").animate({
                                            scrollTop: $(".video-wrapper").offset().top - 25
                                        }, 500);
                                    }
                                }, 100);
                            }, 2000);
                        }
                        $('.static-video iframe').attr('src', 'https://www.youtube.com/embed/' + fistVideo);
                        for (var i = 0; i < data.items.length; i++) {
                            try {
                                var vidId = data.items[i].snippet.resourceId.videoId;
                                var active = '';
                                if (fistVideo === vidId) {
                                    active = 'selected';
                                }
                                var tmpTitle = data.items[i].snippet.title;
                                var vidTitle = tmpTitle.toUpperCase();
                                var vidThumbnail = data.items[i].snippet.thumbnails.high.url;
                                var tmp = '<li data-id="' + vidId + '" class="' + active + '"><div class="play-icon"><span class="center icon-play2"></span><div class="thumbnail-title">' + vidTitle + '</div></div><img src="' + vidThumbnail + '"></li>';
                                $("#thumbnail-gallery").append(tmp);
                            } catch (e) { }
                        }
                        $('#thumbnail-gallery li').on('click', function () {
                            var id = $(this).data('id');
                            $('#thumbnail-gallery li').removeClass('selected');
                            $(this).addClass('selected');
                            $('.static-video iframe').attr('src', 'https://www.youtube.com/embed/' + id);

                            $('#videoTakeover').show();
                            //{

                            //    if (!$(event.target).closest(".modal,.js-open-modal").length) {
                            //        $("body").find(".modal").removeClass("visible");
                            //    }
                            //    $('#videoTakeover').css('padding-top', 0);
                            //});
                        });

                        onclick = "document.getElementById('videoTakeover').style.display='none'";
                        $('#videoTakeover .closeTakeover').on('click', function () {
                            $('#videoTakeover').hide();
                        });

                        $('#thumbnail-gallery').slick({
                            infinite: true,
                            prevArrow: $('.video-wrapper .glyphicon-chevron-left'),
                            nextArrow: $('.video-wrapper .glyphicon-chevron-right'),
                            slidesToShow: 6,
                            slidesToScroll: 2,
                            autoplaySpeed: 3000,
                            autoplay: true,
                            responsive: [{
                                breakpoint: 1300,
                                settings: {
                                    dots: false,
                                    infinite: true,
                                    slidesToShow: 4,
                                    slidesToScroll: 2,
                                    autoplaySpeed: 3500,
                                    autoplay: true
                                }
                            },
                            {
                                breakpoint: 999,
                                settings: {
                                    dots: false,
                                    infinite: true,
                                    slidesToShow: 3,
                                    slidesToScroll: 3,
                                    autoplaySpeed: 4000,
                                    autoplay: true,
                                }
                            }]
                        });
                    }
                });

                $(document).on('click', '#videoTakeover .closeTakeover', function () {
                    $('#videoTakeover .static-video iframe').attr('src', '');
                });
            }
        };

        return YoutubeAPI;
    })();

}).call(this);
/********************************************************************

  New Class Instance 
  Plugin.Google Map
  Return false
  Paramaters false 

*********************************************************************/

;(function () {
  
  'use strict'; 
  
  var GoogleMap = Plugin.GoogleMap = (function () {
      
    function GoogleMap(){
     var scope = this;
     this.homeLocation = {lat: 39.5, lng: -98.35 };
     this.init();
    }
  
    GoogleMap.prototype = {

      init : function () {
        var scope = this;
        var mapIcon = 'dist/images/map-icon.png';
        var map = new google.maps.Map(document.getElementById('map'), {
          zoom: 5,
          center: scope.homeLocation,
          scrollwheel: false,
          backgroundColor:"#eeeeee",
          mapTypeControl: false,
          mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: google.maps.ControlPosition.TOP_CENTER
          },
          zoomControl: true,
          zoomControlOptions: {
              position: google.maps.ControlPosition.BOTTOM_RIGHT
          },
          scaleControl: true,
          streetViewControl: false,
          streetViewControlOptions: {
              position: google.maps.ControlPosition.LEFT_TOP
          },
          fullscreenControl: false
        });

        var style = [
        { "elementType": "geometry", "stylers": [
        { "saturation": -100 }
        ]
        }];

        var mapType = new google.maps.StyledMapType(style, {name:"Grayscale"});
        map.mapTypes.set('grey', mapType);
        map.setMapTypeId('grey');

        var feed = 'https://gomra2015.richmondday.com/api/GetAllMobileTracking';
        $.ajax({
          dataType: 'json',
          url: feed,
          success: function (data) {
            for(var i = 0; i < data.length; i++ ){
                var latLng = {lat: data[i].Lat, lng: data[i].Lon};
                var marker = new google.maps.Marker({
                  position: latLng,
                  map: map,
                  icon: mapIcon,
                  title: data[i].City
                });

                marker.info = new google.maps.InfoWindow({
                  content: '<strong>'+ data[i].Street + '</strong>' +'<br>'+ data[i].City +', '+ data[i].County +'<br>'+ data[i].TimeStamp 
                });

                google.maps.event.addListener(marker, 'click', function() {  
                  //  var marker_map = this.getMap();
                   // this.info.open(marker_map,this);
                });
            }

          }
        });

      }

    };
    
   return GoogleMap;  
  
  })();

}).call(this);
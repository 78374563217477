/********************************************************************

  New Class Instance 
  Plugin.Popup Gallery
  Return false
  Paramaters false 

*********************************************************************/

;(function () {
  
  'use strict'; 
  
  var PopupGallery = Plugin.PopupGallery = (function (id,imageId) {
      
    function PopupGallery(id,imageId){

     var scope = this;
     this.galleryID = id;
     this.imageId = imageId;
     this.count = 0;
     this.data = [];
     this.init();

    }


    PopupGallery.prototype = {

      init : function () {
       
        // scope is PopupGallery "this"
        var scope = this;
        var feed = 'https://gomra2015.richmondday.com/api/GetGalleryByID/'+this.galleryID;  
        $.ajax({
          dataType: 'json',
          url: feed,
          success: function (data) {
             
            scope.data = data;
            $('.popup-gallery').slideDown();

            if($('#ui-gallery').hasClass('slick-initialized')) {
                 $('#ui-gallery').slick("unslick");
            } 

            $("#ui-gallery").html('');
             var images = data.GalleryImages;
             if(scope.imageId){
                scope.swapArrayElements(images, scope.imageId, 0)
             }
             for (var i = 0; i < images.length; i++) {
                 var title = "";
                 var alt = "";
                 if (images[i].Title != undefined) { title = images[i].Title; }
                 if (images[i].AltText != undefined) { alt = images[i].AltText; }
                 var li = '<li><img title="' + title + '" alt="' + alt + '" src="https://gomra2015.richmondday.com/' + images[i].ImagePath.split('~/')[1] + '"/></li>';
                $("#ui-gallery").append(li);
             }


          $('#ui-gallery').slick({

              //
              infinite: true,
              prevArrow: $('.popup-gallery .glyphicon-chevron-left'),
              nextArrow: $('.popup-gallery .glyphicon-chevron-right'),

              // 
              responsive: [{

                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    infinite: true
                  }

                }, {

                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    dots: false
                  }

                } ]
            });

            // 
            $('.close-carousel').on('click', function () {
               $('.popup-gallery').slideUp(function () {
                  $("#ui-gallery").html('');
               });
          
            });


            }
          });
          
      },


      swapArrayElements : function(arr, indexA, indexB) {
        var temp = arr[indexA];
        arr[indexA] = arr[indexB];
        arr[indexB] = temp;
      }

    

    };
    
   return PopupGallery;  
  
  })();

}).call(this);
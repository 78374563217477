/********************************************************************

  New Class Instance
  Plugin.Case Studies
  Return false
  Paramaters false

*********************************************************************/

; (function () {

    'use strict';

    var CaseStudies = Plugin.CaseStudies = (function () {

        function CaseStudies() {
            var scope = this;
            this.count = 0;
            this.data = [];
            this.modalGallery = {};
            this.currentSelected = 0;
            this.totalCount = -1;
            this.quoteHolder;
            this.init();
        }

        CaseStudies.prototype = {

            init: function () {

                // load partial
                $('#case-study-wrapper .view-loader').load('/assets/partials/old-case-studies.html', function () {

                    //
                    $('.load-more').on('click', function () {
                        var loadCase = scope.data;
                        console.log(loadCase)
                        $('.loader').fadeIn(function () {
                            scope.buildBlock(loadCase);
                        });
                        if (scope.count >= scope.data.length) {
                            $('.load-more').remove();
                        }
                    });

                    //
                    $('.close-modal').on('click', function () {
                        $('.case-modal').slideUp();
                        $('.case-wrapper').slideDown();
                    });


                    $('.prev-study').on('click', function (e) {
                        e.preventDefault();
                        scope.currentSelected -= 1;
                        if (scope.currentSelected < 0) {
                            scope.currentSelected = scope.data.length - 1;
                        }
                        scope.buildModal(scope.currentSelected, 'prev');
                    });

                    $('.next-study').on('click', function (e) {
                        e.preventDefault();
                        scope.currentSelected++;

                        if (scope.currentSelected >= scope.data.length) {
                            scope.currentSelected = 0;
                        }
                        scope.buildModal(scope.currentSelected, 'next');
                    });

                });

                // scope is CaseStudies "this"
                var scope = this;
                var feed = 'https://gomra2015.richmondday.com/api/GetProjects/';
                $.ajax({
                    dataType: 'json',
                    url: feed,
                    success: function (data) {
                        // console.log( data );
                        scope.data = data;
                        //scope.data.insert(2,{"Quote":"The Fast & Secure tour has proved to be the best possible setting to showcase our products. The versatility of the design and the flexibility in scheduling has allowed us to bring an engaging and interactive experience directly to more clients than we thought possible.","Author":"- Rodney Mock, Fortinet"});
                        ////
                        //scope.data.insert(10,{"Quote":"An outstanding team from Mayo and MRA came together to make the mobile exhibit a reality, bringing Mayo Clinic’s message of hope and healing to the people across the country and into Canada.","Author":"- Jeanne M. Klein, Mayo Clinic"});
                        var loadCase = data[scope.count];
                        scope.buildBlock(data);

                        //
                        scope.buildMobile();

                    }
                });


            },

            //
            getID: function (num) {
                var r = 0;
                for (var i = 0; i < this.data.length; i++) {
                    if (i === num) {
                        r = this.data[i].ProjectID;
                    }
                }
                return r;
            },

            //
            buildBlock: function (gallery) {

                var val1 = this.totalCount + 1;
                var val2 = this.totalCount + 2;
                var val3 = this.totalCount + 3;
                var val4 = this.totalCount + 4;
                /*var val5 = this.totalCount+5;var val6 = this.totalCount+6;*/

                console.log(val1, val2, val3, val4)

                //if( val6 >= gallery.length ){
                //  val6 = 0;$('.load-more').remove();
                //}
                //if( val5 >= gallery.length ){
                //  val5 = 0;$('.load-more').remove();
                //}
                if (val4 >= gallery.length) {
                    val4 = 0; $('.load-more').remove();
                }
                if (val3 >= gallery.length) {
                    val3 = 0; $('.load-more').remove();
                }
                if (val2 >= gallery.length) {
                    val2 = 0; $('.load-more').remove();
                }
                if (val1 >= gallery.length) {
                    val1 = 0; $('.load-more').remove();
                }

                var scope = this;
                var quote = "";
                var quote2 = '';

                //if(gallery[val3].Quote){
                //quote = '<blockquote>'+gallery[val3].Quote+'</blockquote><p class="author">'+gallery[val3].Author+'</p>';
                //} else{
                quote = '<div data-id="' + gallery[val3].ProjectID + '" class="col-md-3 case-item" onClick="reply_click(' + gallery[val3].ProjectID + ')">' +
                    '<div  class="block-inner">' +
                    '<div class="popover-title">' +
                    '<h5>' + gallery[val3].Company + '</h5>' +
                    '<div class="plus-icon">' +
                    '<h6>+</h6>' +
                    '</div>' +
                    '</div>' +
                    '<img src="https://gomra2015.richmondday.com/' + gallery[val3].GalleryImages[0].ImagePath.split('~/')[1] + '">' +
                    '</div>' +
                    '</div>';
                //}

                //if(gallery[val5].Quote){
                //quote2 = '<blockquote>'+gallery[val5].Quote+'</blockquote><p class="author">'+gallery[val5].Author+'</p>';
                //} else{
                //quote2 = '<div data-id="' + gallery[val5].ProjectID + '" class="col-md-3 case-item" onClick="reply_click(' + gallery[val5].ProjectID + ')">' +
                //            '<div  class="block-inner">'+
                //                '<div class="popover-title">'+
                //                    '<h5>' + gallery[val5].Company + '</h5>'+
                //                    '<div class="plus-icon">'+
                //                        '<h6>+</h6>'+
                //                    '</div>'+
                //                '</div>'+
                //                '<img src="https://gomra2015.richmondday.com/' + gallery[val5].GalleryImages[0].ImagePath.split('~/')[1] + '">'+
                //            '</div>'+
                //        '</div>';
                //}


                var tmp = '<div id="case-' + this.count + '" class="case-block row">';
                if (gallery[val1].GalleryImages.length > 0) {
                    tmp +=
                        '<div data-id="' + gallery[val1].ProjectID + '" class="col-md-3 case-item" onClick="reply_click(' + gallery[val1].ProjectID + ')">' + '<div class="block-inner">' +
                        '<div class="popover-title">' +
                        '<h5>' + gallery[val1].Company + '</h5>' +
                        '<div class="plus-icon">' +
                        '<h6>+</h6>' +
                        '</div>' +
                        '</div>' +
                        '<img src="https://gomra2015.richmondday.com/' + gallery[val1].GalleryImages[0].ImagePath.split('~/')[1] + '">' +
                        '</div>' +
                        '</div>';
                }
                if (val2 > 0 && gallery[val2].GalleryImages.length > 0) {
                    tmp +=
                        '<div data-id="' + gallery[val2].ProjectID + '" class="col-md-3 case-item" onClick="reply_click(' + gallery[val2].ProjectID + ')">' +
                        '<div class="block-inner">' +
                        '<div class="popover-title">' +
                        '<h5>' + gallery[val2].Company + '</h5>' +
                        '<div class="plus-icon">' +
                        '<h6>+</h6>' +
                        '</div>' +
                        '</div>' +
                        '<img class="post-thumb-sm" src="https://gomra2015.richmondday.com/' + gallery[val2].GalleryImages[0].ImagePath.split('~/')[1] + '">' +
                        '</div>' +
                        '</div>';
                }
                if (val3 > 0 && gallery[val3].GalleryImages.length > 0) {
                    tmp += quote;
                }
                if (val4 > 0 && gallery[val4].GalleryImages.length > 0) {
                    tmp +=
                        '<div data-id="' + gallery[val4].ProjectID + '"class="col-md-3 case-item" onClick="reply_click(' + gallery[val4].ProjectID + ')">' +
                        '<div class="block-inner">' +
                        '<div class="popover-title">' +
                        '<h5>' + gallery[val4].Company + '</h5>' +
                        '<div class="plus-icon">' +
                        '<h6>+</h6>' +
                        '</div>' +
                        '</div>' +
                        '<img src="https://gomra2015.richmondday.com/' + gallery[val4].GalleryImages[0].ImagePath.split('~/')[1] + '">' +
                        '</div>' +
                        '</div>';
                }
                //if(val5 > 0){
                //    tmp += quote2;
                //}
                //if(val6 > 0){
                //    tmp += '';
                //}

                tmp +=
                    '</div>' +
                    '</div>';


                // loader
                $(tmp).imagesLoaded(function () {
                    $('.case-wrapper').append(tmp);
                    $('.loader').fadeOut(600, function () {
                        $('.case-block').addClass('on');
                        $('.case-item').on('click', function () {
                            var id = parseInt($(this).data('id'));
                            scope.buildModal(id, 'next');
                        });

                    });
                });

                this.totalCount += 4;
                this.count++;

            },

            //
            buildMobile: function () {

                var list = "<ul class='mobile-case-studies'>";
                var idCount = -1;

                for (var i = 0; i < this.data.length; i++) {
                    // console.log(this.data[i].Company + " ID " + i);

                    if (i === 0 || i === 7 || i === 13 || i === 12 || i === 1 || i === 6 || i === 15 || i === 16 || i === 17 || i === 19 || i === 20 || i === 21) {
                        idCount++;
                        var class = "";
                        var firstImage = "";
                        if (!this.data[i].Quote && this.data[i].GalleryImages.length > 0) {
                            firstImage = 'https://gomra2015.richmondday.com/' + this.data[i].GalleryImages[0].ImagePath.split('~/')[1];
                        }

                        if (this.data[i].Quote) { class="hidden"; }
                        list += "<li class=" + class+ ">";
                        list += "<div class='class-img'><img src=" + firstImage + "></div>";
                        list += "<div class='list-title'><div class='plus-sign glyphicon glyphicon-plus'></div>";
                        list += "<span>" + this.data[i].Company + "</span>";
                        list += "</div>";
                        list += "<div class='content-hidden'>";
                        list += "<div class='case-mobile-title-block'>";
                        list += "<h2>" + this.data[i].Company + "</h2>";
                        if (this.data[i].Subject) {
                            //list += "<h3>"+this.data[i].Subject +"</h3>";
                        }
                        list += "</div>";
                        list += "<h5>THE CHALLENGE</h5>";
                        list += "<p>" + this.data[i].Challenge + "</p>";
                        list += "<h5>THE SOLUTION</h5>";
                        list += "<p>" + this.data[i].Solution + "</p>";

                        // add gallery
                        if (!this.data[i].Quote && this.data[i].GalleryImages.length > 0) {
                            var gallery = "<div id=wrap-" + idCount + "><ul class='mobile-case-gallery'>";
                            for (var a = 0; a < this.data[i].GalleryImages.length; a++) {
                                gallery += '<li><img data-lazy="https://gomra2015.richmondday.com/' + this.data[i].GalleryImages[a].ImagePath.split('~/')[1] + '"></li>';

                            }

                            gallery += "</ul>";
                            gallery += '<div class="carousel-nav"><div class="control right glyphicon glyphicon-chevron-right"></div><div class="gallery-counter"><span class="gal-slide">1</span> 0f <span class="gal-total">12</span></div><div class="control left glyphicon glyphicon-chevron-left"></div></div></div>';

                            list += gallery;
                        }

                        list += "</div>";
                        list += "</li>";
                    }
                }

                list += "</ul>";
                $('.mobile-case-wrapper').append(list);

                $('.mobile-case-gallery').each(function (idx, item) {
                    var carouselId = "wrap-" + idx;

                    this.id = carouselId;
                    $(this).slick({
                        infinite: true,
                        lazyLoad: 'ondemand',

                        prevArrow: $("#" + this.id + ' .carousel-nav .glyphicon-chevron-left'),
                        nextArrow: $("#" + this.id + ' .carousel-nav .glyphicon-chevron-right')

                    });

                    var total = $(this).slick("getSlick").slideCount;
                    $("#" + carouselId).find('.gal-total').text(total);

                });


                //
                $('.mobile-case-gallery').on('afterChange', function (event, slick, currentSlide, nextSlide) {
                    $(this).parent().find('.gal-slide').text((currentSlide + 1));
                });

                //
                $('.mobile-case-studies li .list-title, .mobile-case-studies li .class-img').on('click', function () {
                    var open = $(this).parent().find('.content-hidden');
                    var sign = $(this).parent().find('.plus-sign');

                    if ($(open).is(":visible")) {
                        $(open).slideUp();
                        $(sign).removeClass('glyphicon-minus');
                        $(sign).addClass('glyphicon-plus');

                    } else {
                        $(open).slideDown();
                        $(sign).addClass('glyphicon-minus');
                        $(sign).removeClass('glyphicon-plus');
                    }
                });
            },
        };

        return CaseStudies;

    })();

}).call(this);

var redirUrl = "/case-studies?id="
function reply_click(clicked_id) {
    window.location.href = redirUrl + clicked_id;
}

//
Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
};
(function () {
    'use strict';

    var InteriorPages = Plugin.InteriorPages = (function () {

        function InteriorPages() {
            var scope = this;
            this.init();
        }

        InteriorPages.prototype = {

            init: function () {
                // scope is InteriorPages "this"
                var scope = this;

                this.page = window.location.pathname;

                // load view
                $('.view-loader').load('/assets/partials' + this.page + '.html', function () {
                    scope.PartialEvents();
                });

                // uses "careers" page header image for all job postings
                if (this.page.includes("/jobs/")) {
                    this.page = "careers";
                }

                this.page = this.page.replace(/\//g, "");

                this.tryToHighlightSubnavTab();

                this.updateMetaData();
            },

            // metadata
            updateMetaData: function () {
                if (this.page == "subscribe") {
                    $('meta[name="og:image"]').attr("content", "/dist/images/subscribe.jpg");
                    $('meta[name="og:description"]').attr("content", "Stay up to speed by subscribing to MRA�s newsletters and tour updates.");
                }
            },

            // page events
            PartialEvents: function () {

                var scope = this;
                var page = this.page;

                if (page == 'used-vehicles') {
                    this.loadOnLotData();
                }

                if (page == 'careers') {
                    this.getCareerData();
                }

                if (page == 'case-studies') {
                    $('header.header').addClass('black-background-header');
                    $('.covid-banner .main').addClass('with-black-background');
                }

                // load header and image
                $('.header-loader .header-content').load('/assets/partials/page-header.html', function () {

                    var banner = '<li class="background full-list-item has-mobile" style="background-image:url(/dist/images/' + page + '.jpg)" data-desktop="' + page + '.jpg" data-mobile="' + page + '.jpg"></li>';

                    if (page == "containers") {
                        banner += '<video onloadeddata="this.play();" playsinline loop muted controls class="header-videos" controlsList="nodownload"><source src="/dist/images/Expandable-Container-sm-compressed.mp4" type="video/mp4" /></video>';
                    }

                    if (page == "healthcare") {
                        banner += '<video onloadeddata="this.play();" playsinline loop muted controls class="header-videos" controlsList="nodownload"><source src="/dist/images/MRA_Healthcare_Video-compressed.mp4" type="video/mp4" /></video>';
                    }

                    if (page == "outreach") {
                        banner = '<li class="background full-list-item has-mobile" style="background-image:url(/dist/images/' + page + '.jpg); background-position-y: 80%;" data-desktop="' + page + '.jpg" data-mobile="' + page + '.jpg"></li>';
                    }

                    $('.page-header').append(banner);

                    //$('.page-header').append("<script type=\"text/javascript\">alert(\"start\");        window.onload= function(){	var x = document.getElementById(\"headerContainerVideoID\").play();          alert(\"end\");</script>");

                    // check for mobile
                    var mobile = new Plugin.Mobile();

                    // slider
                    var slider = $('#image-gallery').lightSlider({
                        item: 1,
                        slideMove: 1,
                        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
                        speed: 600,
                        controls: false,
                        slideMargin: 10,
                        loop: false,
                        enableTouch: true,
                        enableDrag: true,
                        freeMove: true,
                        auto: true,
                        pause: 6000,
                        pauseOnHover: true,
                        swipeThreshold: 40,
                        responsive: [{
                            breakpoint: 800,
                            settings: {
                                item: 1,
                                slideMove: 1,
                                slideMargin: 6,
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                item: 1,
                                slideMove: 1
                            }
                        }
                        ],
                        onSliderLoad: function () {
                            $('#image-gallery').removeClass('cS-hidden');
                            var pagerCount = 0;
                            $('.lSPager li').each(function () {
                                pagerCount++;
                            });
                            if (pagerCount <= 1) {
                                $('.lSPager').hide();
                            }
                        }
                    });

                });


                if ($(".tour-gallery-loader").length > 0) {
                    var id = $('.tour-gallery-loader').data('gallery');
                    if ($(".tour-gallery-loader").hasClass("single-image")) {
                        $('.tour-gallery-loader').load('/assets/partials/single-thumb-gallery.html', function () {
                            var tourGallery = new Plugin.TourGallery(id);
                        });
                    } else {
                        $('.tour-gallery-loader').load('/assets/partials/tour-gallery.html', function () {
                            var tourGallery = new Plugin.TourGallery(id);
                        });
                    }
                }

                // video container
                var video = document.getElementById("video");
                $('.video-play-btn').on('click', function () {
                    $('.video-play-btn').fadeOut();
                    $('#video-modal').addClass('videoOpen');
                    //$(".video-p").css("background-position", "-9000px -9000px");
                    $(".video-p img").css("display", "none");
                    video.play();
                });

                $('.video-close').on('click', function () {
                    $("video").each(function () {
                        this.pause()
                    });
                    $('.video-play-btn').fadeIn();
                    $('#video-modal').removeClass('videoOpen');
                    //$(".video-p").css("background-position", "");
                    $(".video-p img").css("display", "block");
                });

                //
                if ($(".the-process-loader").length > 0) {
                    $('.the-process-loader').load('/assets/partials/the-process.html');
                }

                //
                if ($(".our-program-banner").length > 0) {
                    scope.loadProgramData();
                }

            },

            //
            loadProgramData: function () {

                // currently the data for this is local.
                var scope = this;
                var feed = 'assets/temp-data/our-program-banner.json';
                var programData = [];
                $.ajax({
                    dataType: 'json',
                    url: feed,
                    success: function (data) {

                        programData = data;
                        //
                        $('.plus').on('click', function () {
                            var item = $(this).data('item');
                            loadSlide(item);
                            $('.our-program-banner').slideDown();
                        });

                        // load mobile data
                        loadMobileData();

                    },
                    error: function () {
                        console.log("NO FEED DATA ");
                    }
                });


                //
                $('.our-program-banner .close-btn').on('click', function () {
                    $('.our-program-banner').slideUp();
                });

                function loadSlide(item) {
                    for (var i = 0; i < programData.Featured.Items.length; i++) {
                        var id = programData.Featured.Items[i].ID;
                        if (id == item) {
                            var title = programData.Featured.Items[i].Title;
                            var desc = programData.Featured.Items[i].Description;
                            var image = '<img class="image" src="' + programData.Featured.Items[i].Image + '">';
                            $('.slide-image').html('');
                            $('.slide-image').append(image);
                            $('.slide-data h3').html(title);
                            $('.slide-data p').html(desc);
                        }

                    }

                }

                //
                function loadMobileData() {

                    var list = "<ul>";
                    for (var i = 0; i < programData.Featured.Items.length; i++) {
                        var id = programData.Featured.Items[i].ID;
                        var title = programData.Featured.Items[i].Title;
                        var desc = programData.Featured.Items[i].Description;
                        var image = '<img class="image" src="' + programData.Featured.Items[i].Image + '">';
                        list += "<li><div class='list-title'><div class='plus-sign glyphicon glyphicon-plus'></div>";
                        list += title;
                        list += "</div>";
                        list += "<div class='content-hidden'>";
                        list += "<div class='class-img'>" + image + "</div>";
                        list += "<p>" + desc + "</p>";
                        list += "</div>";
                        list += "</li>";
                    }

                    list += "<ul>";
                    $('.plus-list').append(list);

                    //
                    $('.plus-list li').on('click', function () {

                        var open = $(this).find('.content-hidden');
                        var sign = $(this).find('.plus-sign');

                        $('.plus-list li .content-hidden').hide();
                        $('.plus-list li .content-hidden .plus-sign').removeClass('glyphicon-minus');
                        $('.plus-list li .content-hidden .plus-sign').addClass('glyphicon-plus');

                        $(open).slideDown();
                        $(sign).addClass('glyphicon-minus');
                        $(sign).removeClass('glyphicon-plus');

                    });

                }

            },

            getCareerData: function () {
                function GetURLParameter(sParam) {
                    var sPageURL = window.location.search.substring(1);
                    var sURLVariables = sPageURL.split('&');
                    for (var i = 0; i < sURLVariables.length; i++) {
                        var sParameterName = sURLVariables[i].split('=');
                        if (sParameterName[0] == sParam) {
                            return sParameterName[1];
                        }
                    }
                }

                var ref;
                $.ajax({
                    type: 'GET',
                    dataType: 'json',
                    url: 'https://gomrajobs.cdn.prismic.io/api/v2/',
                    success: function (data) {
                        ref = data.refs[0].ref;

                        if (window.location.href.indexOf('job-post') === -1) { // career page
                            var jobs = [];
                            $.ajax({
                                type: 'GET',
                                dataType: 'json',
                                url: 'https://gomrajobs.cdn.prismic.io/api/v2/documents/search?ref=' + ref,
                                success: function (data) {
                                    jobs = data;
                                    console.log(jobs);
                                    var jobList = '';
                                    for (var i = 0; i < jobs.results.length; i++) {
                                        jobList += '<h4><a href="/jobs/job-post?url=' + jobs.results[i].uid + '">' + jobs.results[i].data.job_title[0].text + '</a></h4>';
                                    }
                                    $('.job-postings').html(jobList);
                                },
                                error: function () {
                                    console.log("NO FEED DATA ");
                                }
                            });
                        }
                        else { // job-post page
                            var jobPost = GetURLParameter('url');
                            $.ajax({
                                type: 'GET',
                                dataType: 'json',
                                url: 'https://gomrajobs.cdn.prismic.io/api/v2/documents/search?ref=' + ref + '&q=%5B%5Bat(my.job.uid%2C"'+ jobPost +'")%5D%5D',
                                success: function (data) {
                                    var jobDetails = data.results[0].data;
                                    // set the title
                                    $('#title').html(jobDetails.job_title[0].text);
                                    // set the CTA
                                    $('.cta-btn').attr('href', $('.cta-btn').attr('href') + jobDetails.job_title[0].text);
                                    var innerHTML = '';
                                    // check for summary and add
                                    if (jobDetails.summary.length > 0) {
                                        innerHTML += '<div class="image-and-desc">';
                                        innerHTML += '<h4>SUMMARY</h4>';
                                        innerHTML += '<p>' + jobDetails.summary[0].text + '</p>';
                                        innerHTML += '</div>';
                                        console.log("add summary");
                                    }
                                    var sections = jobDetails.header_and_content;
                                    for (var i = 0; i < sections.length; i++) {
                                        innerHTML += '<div class="image-and-desc">';
                                        innerHTML += '<h4>' + sections[i].orange_heading[0].text + '</h4>';
                                        var innerSections = [];
                                        var innerSectionCounter = -1;
                                        // create inner sections
                                        if (sections[i].content[0].type == "heading3") {
                                            console.log("heading found");
                                            for (var k = 0; k < sections[i].content.length; k++) {
                                                if (sections[i].content[k].type == "heading3") {
                                                    innerSections.push({
                                                        subtitle: sections[i].content[k].text,
                                                        list: []
                                                    });
                                                    innerSectionCounter++;
                                                } else {
                                                    innerSections[innerSectionCounter].list.push(sections[i].content[k].text);
                                                }
                                            }
                                            for (var k = 0; k < innerSections.length; k++) {
                                                innerHTML += '<h3>' + innerSections[k].subtitle + '</h3>';
                                                innerHTML += '<ul>';
                                                for (var l = 0; l < innerSections[k].list.length; l++) {
                                                    innerHTML += '<li>' + innerSections[k].list[l] + '</li>';
                                                }
                                                innerHTML += '</ul>';
                                            }
                                        } else { // no inner sections, just output the list
                                            innerHTML += '<ul>';
                                            for (var k = 0; k < sections[i].content.length; k++) {
                                                innerHTML += '<li>' + sections[i].content[k].text + '</li>';
                                            }
                                            innerHTML += '</ul>';
                                        }
                                        innerHTML += '</div>';
                                        $('#content').html(innerHTML);
                                    }
                                },
                                error: function () {
                                    console.log("NO FEED DATA ");
                                }
                            });

                        }
                    },
                    error: function () {
                        console.log("NO CONNECTION TO GOMRAJOBS");
                    }
                });
            },

            //
            loadOnLotData: function () {

                // scope is TourGallery "this"
                var scope = this;
                var feed = 'https://gomra2015.richmondday.com/api/GetGalleryByPageGroup/92860ed7-e7c7-41ae-820c-5212b0b67870';
                //feed = 'http://localhost:666/api/GetGalleryByPageGroup/92860ed7-e7c7-41ae-820c-5212b0b67870';     //for localy testing API

                $.ajax({
                    dataType: 'json',
                    url: feed,
                    success: function (data) {
                        //  console.log(data);
                        for (var i = 0; i < data.length; i++) {
                            var li = scope.onLotTemplate(data[i].Thumbnail, data[i].Name, data[i].Description, data[i].GalleryID);
                            $('.vehicle-list').append(li);
                        }

                        $('.view-gallery').on('click', function () {
                            var id = $(this).data('id');
                            var gallery = new Plugin.PopupGallery(id);
                        });

                    }
                });

            },

            //
            onLotTemplate: function (img, title, desc, PageGroup) {
                var displayImg = '';
                if (img !== null) {
                    displayImg = '<img src="https://gomra2015.richmondday.com/' + img.split('~/')[1] + '">';
                } else {
                    displayImg = '<img src="/dist/images/on-lot-images/coming-soon.jpg">';
                }

                var tmp = '<li>' +
                    '<span class="left-side">' + displayImg +
                    '<button data-id="' + PageGroup + '" class="view-gallery pull-left">VIEW GALLERY</button>' +
                    '</span>' +
                    '<span class="right-side">' +
                    '<h3>' + title + '</h3>' +
                    '<p>' +
                    desc.replace(/<\/?span[^>]*>/g, "").replace(/<\/?font[^>]*>/g, "") +
                    '</p>' +
                    '</span>' +
                    '<button data-id="' + PageGroup + '" class="view-gallery pull-left mobile-only">VIEW GALLERY</button>' +
                    '</li>';

                return tmp;

            },

            tryToHighlightSubnavTab: function () {
                var subNavs = ["expandables", "tow-behinds", "coaches", "boxes", "custom", "containers"];

                var interval = setInterval(function () {
                    if ($(".vehicle-styles-nav").length > 0) {
                        clearInterval(interval);

                        subNavs.forEach(function (subNav) {
                            if (window.location.href.indexOf(subNav) > -1) {
                                $(".vehicle-styles-nav a[href = '/" + subNav + "']").addClass("active");
                            }
                        });
                    }
                }, 250);

                setTimeout(function () {
                    clearInterval(interval);
                }, 5000);
            }


        };

        return InteriorPages;

    })();

}).call(this);
(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    const topBanner = function () {
        const desktopImage = document.querySelector("#top-popup .deskonly");
        const mobileImage = document.querySelector("#top-popup .mobileonly");
        const link = document.querySelector("#top-popup a");
        const hourMod = new Date().getHours() % 4;
        const topBanners = [
            {
                desktop: "/assets/images/top-banner/GoMRA_PopUpBanner_roadshows.jpg",
                mobile: "/assets/images/top-banner/GoMRA_PopUpBanner_roadshows_mobile.jpg",
                link: "https://blogs.gomra.com/2022/april/20/roadshows-stuff-episode-5-working-at-mra/"
            },
            {
                desktop: "/assets/images/top-banner/GoMRA_PopUpBanner_expandable.jpg",
                mobile: "/assets/images/top-banner/GoMRA_PopUpBanner_expandable_mobile.jpg",
                link: "https://blogs.gomra.com/2022/april/07/the-future-of-mobile-double-expandable-containers/"
            },
            {
                desktop: "/assets/images/top-banner/GoMRA_PopUpBanner_healthcare.jpg",
                mobile: "/assets/images/top-banner/GoMRA_PopUpBanner_healthcare_mobile.jpg",
                link: "/healthcare"
            },
            {
                desktop: "/assets/images/top-banner/GoMRA_PopUpBanner_newsletter.jpg",
                mobile: "/assets/images/top-banner/GoMRA_PopUpBanner_newsletter_mobile.jpg",
                link: "/subscribe"
            },
        ];

        topBanners.map(function (banner, index) {
            if (index == hourMod) {
                desktopImage.src = banner.desktop;
                mobileImage.src = banner.mobile;
                link.href = banner.link;
            }
        });
    };
    const formFooterInit = function () {
        const isEmailValid = function (email) {
            return (email.match(/\b(^(\S+@).+((\.com)|(\.net)|(\.edu)|(\.mil)|(\.gov)|(\.org)|(\..{2,2}))$)\b/gi) || "").length > 0;
        };

        $(document).on('click', '.footer .subscribe-btn', function (e) {
            const $formFooter = $(this).parents('form');
            if ($formFooter.find('.first_name').val() < 1) { alert('Please enter your first name.'); return false; }
            if ($formFooter.find('.last_name').val() < 1) { alert('Please enter your last name.'); return false; }
            if ($formFooter.find('.email').val() < 1) { alert('Please enter your email address.'); return false; }
            if ($formFooter.find('.phone').val() < 1) { alert('Please enter your phone number.'); return false; }
            if ($formFooter.find('.company').val().length < 1) { alert('Please enter your company name.'); return false; }

            if (!isEmailValid($formFooter.find('.email').val())) {
                alert('Please enter a valid e-mail address.');
                return false;
            }
            if ($formFooter.find('[name=00NE0000000bJrJ]:checked').length == 0) {
                alert("Please select at least one newsletter.");
                return false;
            }
        });
    };

    var MRA = {
        // All pages
        'common': {
            init: function () {
                if (window.location.toString().indexOf("localhost") <= 0 && window.location.toString().indexOf("staging") <= 0) {
                    if (location.protocol !== 'https:') {
                        location.replace('https:' + location.href.substring(location.protocol.length));
                    }
                }

                topBanner();
                $('#top-popup.toggle').toggleClass('hidden');
                $('body').addClass('covid-banner');

                // load header
                $('.header').load('/assets/partials/header.html', function () {
                    // lines button
                    $('.lines-button').on('click', function (e) {
                        e.preventDefault();
                        var $headerEl = $('header.header');

                        if ($(this).is('.open')) {
                            $(this).removeClass('open');
                            $('.desktop-full-nav').slideUp();
                            $headerEl.attr('style', '');
                            $('body').attr('style', '');
                        } else if (!$(this).is('.open')) {
                            $(this).addClass('open');
                            $('.desktop-full-nav').slideDown();
                            $headerEl.css({
                                height: '100%',
                                'overflow-x': 'hidden',
                                'overflow-y': 'auto'
                            });
                            $('body').css({
                                'overflow': 'hidden',
                                'height': '100vh'
                            });
                        }
                        return false;
                    });

                    //
                    $('.desktop-full-nav li').on('click', function (e) {
                        if ($(e.target).parents(".nav-dropdown").length < 1) {
                            e.preventDefault();
                            var url = $(this).find('a').attr('href');
                            window.location = url;
                        }
                    });

                    $('.dropdown-submenu').on("hover", function (e) {
                        $(this).next('ul').toggle();
                        e.stopPropagation();
                        e.preventDefault();
                    });

                    $('.desktop-full-nav li').hover(function (e) {
                        if ($(window).width() >= 1200) {
                            $(this).find(".nav-dropdown").addClass("active");
                        }
                    }, function () {
                        if ($(window).width() >= 1200) {
                            $(this).find(".nav-dropdown").removeClass("active");
                        }
                    });
                });

                // load footer
                $('.footer').load('/assets/partials/footer.html');

                // loader
                $('body').imagesLoaded({ background: '.image.background' }, function () {
                    // images have loaded
                    if (!$('body').is('.loaded')) {
                        $('body').addClass("loaded");
                        //$('.banner, .wrap').fadeIn(600);
                        $('.loader').fadeOut(600, function () {
                            // console.log("loaded ");
                        });

                        // check for mobile
                        var mobile = new Plugin.Mobile();

                        var interval = setInterval(function () {
                            var playBtn = $(".video-play-btn");
                            if (playBtn.length) {
                                clearInterval(interval);
                                var flash = function (e) {
                                    playBtn.css("opacity", 0.4);
                                    setTimeout(function () {
                                        playBtn.css("opacity", 1);
                                        setTimeout(function () {
                                            playBtn.css("opacity", 0.4);
                                            setTimeout(function () {
                                                playBtn.css("opacity", 1);
                                            }, 300);
                                        }, 300);
                                    }, 300);
                                };
                                flash();
                                var timer = setInterval(function () {
                                    flash();
                                }, 5000);
                                setTimeout(function () {
                                    clearInterval(timer);
                                }, 16000);
                            }
                        }, 200);

                        setTimeout(function () {
                            clearInterval(interval);
                        }, 25000);

                    }
                });


                // slider
                var slider = $('#image-gallery').lightSlider({
                    item: 1,
                    slideMove: 1,
                    easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
                    speed: 600,
                    controls: false,
                    slideMargin: 10,
                    loop: true,
                    enableTouch: true,
                    enableDrag: true,
                    freeMove: true,
                    auto: true,
                    pause: 5000,
                    swipeThreshold: 40,

                    onSliderLoad: function () {
                        $('#image-gallery').removeClass('cS-hidden');
                        var pagerCount = 0;
                        $('.lSPager li').each(function () {
                            pagerCount++;
                        });
                        if (pagerCount <= 1) { $('.lSPager').hide(); }
                    }
                });

                formFooterInit();
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

            }
        },
        // Home page
        'home': {
            init: function () {
                // setup home page plugins
                var youtube = new Plugin.YoutubeAPI();

                // Quote Galleries
                var quoteGallery = new Plugin.QuoteGallery();

                // Case Studies
                var caseStudies = new Plugin.CaseStudies();

                // Google Map
                var googleMap = new Plugin.GoogleMap();
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

            }
        },
        // interior pages
        'interior': {
            init: function () {

                // setup interior page class
                var pages = new Plugin.InteriorPages();


            },
            finalize: function () {
                // JavaScript to be fired on the interior page, after the init JS

            }
        },

        // contacts page
        'contacts': {
            init: function () {


            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = MRA;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');
            var classnm = document.getElementById("page-container").className;

            // Fire page-specific init JS, and then finalize JS
            if (classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            }


            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    $(window).on('resize', function () {
        if (window.innerWidth >= 1200) {
            $('.lines-button.open').trigger('click');

            setTimeout(function () {
                $('.desktop-full-nav.navonly-desktop').attr('style', '');
            }, 1000);
        }
    });
})(jQuery); // Fully reference jQuery after this point.
/********************************************************************

  New Class Instance 
  Plugin.Quote Gallery
  Return false
  Paramaters false 

*********************************************************************/

;(function () {
  
  'use strict'; 
  
  var QuoteGallery = Plugin.QuoteGallery = (function () {
      
    function QuoteGallery(){

     var scope = this;
     this.init();

    }
  
    QuoteGallery.prototype = {

      init : function () {
       
        // scope is QuoteGallery "this"
        var scope = this;

        // Quote Gallery 
        var quoteGallery = $('#quote-gallery').lightSlider({
            item:1,
            loop:true,
            slideMove:1,
            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
            speed:600,
            auto: true,
            pause: 5000,
            pager:true,
            controls:false,
            slideMargin: 3,
            enableTouch:true,
            enableDrag:true,
            freeMove:true,
            swipeThreshold: 40,
            onSliderLoad: function() {
                  $('#quote-gallery').removeClass('cS-hidden');
              }  
            });
         

      }

    };
    
   return QuoteGallery;  
  
  })();

}).call(this);
/********************************************************************

  New Class Instance 
  Plugin.Forms
  Return false
  Paramaters false 

*********************************************************************/

;(function () {
  
  'use strict'; 
  
  var Forms = Plugin.Forms = (function () {
      
    function Forms(){

     var scope = this;
     this.init();

    }
  
    Forms.prototype = {

      init : function () {
       
        // scope is Forms "this"
        var scope = this;
 
         console.log( "FORMS " );

      },

      //
      subscribe : function () {


      },

      //
      contact : function () {

      }

    };
    
   return Forms;  
  
  })();

}).call(this);